import { Shift } from "@src/lib/interface";

export interface OngoingShiftStore {
  isLoading: boolean;
  shiftList: Shift[];
  shiftInfoList: Shift[];
  actionList: {
    RECORD_SHIFT_TIME_FAILURE: ParamsList[];
    UPLOAD_TIMECARD: ParamsList[];
  };
}

interface ParamsList {
  timestamp: string;
}

export const ActionType = {
  CLEAR_SHIFTS: "CLEAR_SHIFTS",
  SET_STATE_FROM_LOCAL: "SET_STATE_FROM_LOCAL",
  SET_ACTION_LIST: "SET_ACTION_LIST",
  SET_SHIFT_LIST: "SET_SHIFT_LIST",
  SET_SHIFT_INFO_LIST: "SET_SHIFT_INFO_LIST",
  UPDATE_SHIFT: "UPDATE_SHIFT",
  FILTER_RECORD_SHIFT_TIME_FAILURE: "FILTER_RECORD_SHIFT_TIME_FAILURE",
  FILTER_UPDATE_SHIFT_UNIT: "FILTER_UPDATE_SHIFT_UNIT",
  FILTER_UPDATE_WORKER_SIGNATURE: "FILTER_UPDATE_WORKER_SIGNATURE",
  FILTER_UPLOAD_TIMECARD: "FILTER_UPLOAD_TIMECARD",
  FILTER_REQUEST_AUTHORIZED_SIGNATURE: "FILTER_REQUEST_AUTHORIZED_SIGNATURE",
  FILTER_SIGN_WITH_SIGNATURE: "FILTER_SIGN_WITH_SIGNATURE",
  PUSH_UPLOAD_TIMECARD: "PUSH_UPLOAD_TIMECARD",
  PUSH_RECORD_SHIFT_TIME_FAILURE: "PUSH_RECORD_SHIFT_TIME_FAILURE",
} as const;

export interface FetchAgentShiftRequest {
  upcoming?: boolean;
  shiftDate?: string;
  ongoing?: boolean;
}

export interface RatingRequest {
  rating: number;
  reviewFor?: string;
  reasons: string[];
  otherReason: string;
  review: string;
  shiftId: string;
}

export interface NoTimeSheetAvailableReq {
  shiftId: string;
  reason: string;
}
